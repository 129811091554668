// Полоса прокрутки
* {
  scrollbar: {
    width: thin;
    color: var(--color-grey-dark) var(--color-primary);
  }

  &::-webkit-scrollbar {
    @media(pointer: fine) {
      width: 16px;

      &-track {
        background-color: var(--color-primary);

        &-piece {
          margin-block: 8px;
        }
      }

      &-thumb {
        background-color: var(--color-grey-dark);

        border: {
          width : 4px;
          style : solid;
          color : var(--color-primary);
          radius: 8px;
        }

        &:active {
          box-shadow: inset 0 8px 8px var(--color-black-50);
        }
      }

      &-corner {
        background-color: var(--color-primary);
      }
    }
  }
}