// Главный блок
.main {
  min-height: 940px;

  @media(max-width: $lg) {
    & {
      min-height: 700px;
    }
  }

  @media (min-width: 2000px) {
    & {
      min-height: 1300px;
    }
  }

  @media(max-width: $md) {
    & {
      min-height: 630px;
    }
  }
}

// Каталог
.catalog {
  &__bg {
    &::after {
      content   : "";
      position  : absolute;
      inset     : 0;
      transition: 0.3s ease;
      background: linear-gradient(90deg, var(--color-primary) 0%, var(--color-none) 100%);
      opacity   : 0.7;
    }
  }

  &__content {
    max-width : 440px;
    min-height: 375px;
  }

  &__title,
  &__subtitle {
    background-color: var(--color-blue-70);
  }

  &__info {
    transform : scale(0);
    visibility: hidden;
  }

  @media(hover) {
    &__item:hover &__bg::after {
      opacity: 1;
    }

    &__item:hover &__info {
      transform : scale(1);
      opacity   : 1;
      visibility: visible;
    }

    &__item:hover &__title {
      transform: translateY(200px);
    }
  }

  @media(max-width: $md) {
    &__bg {
      &::after {
        opacity: 1;
      }
    }

    &__content {
      max-width : 100%;
      min-height: 220px;
    }
  }
}

// О нас
.about {
  &__image {
    transform: rotate(90deg);
  }

  &__pack {
    max-width: 990px;

    &::before {
      padding-top: 65%;
    }
  }

  &__content {
    max-width: 650px;
  }

  &__doc {
    top   : 0;
    left  : 0;
    bottom: 0;
  }

  @media(min-width: $lg) {
    &__image {
      &--2 {
        left: 500px;
      }
    }

    &__content {
      padding: 100px 80px;
    }
  }

  @media (min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__pack {
      max-width: 700px;
    }

    &__content {
      max-width: 510px;
    }
  }

  @media(max-width: $md) {
    &__pack {
      max-width: 550px;
    }

    &__content {
      max-width: 400px;
    }
  }

  @media(max-width: $sm) {
    &__bg {
      position     : static;
      margin-inline: -15px;
    }

    &__pack,
    &__content {
      max-width: 100%;
    }

    &__content {
      margin-top: -70px;
    }
  }
}

// Подход
.approach {
  &__pack {
    max-width: 1330px;
  }

  &__content {
    max-width: 700px;
  }

  @media(min-width: $lg) {
    &__content {
      padding: 60px;
    }
  }

  @media (min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__pack {
      max-width: 700px;
    }

    &__content {
      max-width: 510px;
    }
  }

  @media(max-width: $md) {
    &__pack {
      max-width: 550px;
    }

    &__content {
      max-width: 500px;
    }
  }

  @media(max-width: $sm) {
    &__bg {
      position     : static;
      margin-inline: -15px;
    }

    &__pack,
    &__content {
      max-width: 100%;
    }

    &__content {
      margin-top: -70px;
    }
  }
}

// Стоимость
.price {
  &__pack {
    max-width: 1030px;

    &--1 {
      top : -300px;
      left: -600px;

      & .image {
        transform: rotate(90deg);
      }
    }

    &--2 {
      bottom: -250px;
      right : -600px;
    }
  }

  &__info {
    max-width: 480px;
  }

  &__content {
    max-width: 590px;
  }

  &__item {
    background-color: var(--color-white-10);
  }

  @media (min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__block {
      flex-direction: column;
      align-items   : flex-start;
    }

    &__content {
      order    : 1;
      max-width: 100%;
    }

    &__info {
      order    : 2;
      max-width: 100%;
    }
  }
}

// Преимущества
.advantages {
  &__item &__pack {
    background: linear-gradient(180deg, var(--color-none) 0%, var(--color-grey-80) 100%);
  }

  @media(min-width: $md) {
    &__item:nth-child(odd) &__pack {
      background: linear-gradient(180deg, var(--color-none) 0%, var(--color-grey-50) 100%);
    }

    &__item:nth-child(even) &__pack {
      background: linear-gradient(180deg, var(--color-grey-50) 0%, var(--color-none) 100%);
    }
  }
}

// Обратная связь
.feedback {
  &__pack {
    max-width: 875px;
  }

  &__content {
    max-width: 725px;
  }

  @media(min-width: $lg) {
    &__image {
      left : auto;
      right: -550px;
    }

    &__content {
      padding: 56px 76px;
    }
  }

  @media (min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }

  @media(max-width: $lg) {
    &__pack {
      max-width: 700px;
    }

    &__content {
      max-width: 540px;
    }
  }

  @media(max-width: $md) {
    &__pack {
      max-width: 500px;
    }

    &__content {
      max-width: 400px;
    }
  }

  @media(max-width: $sm) {
    &__bg {
      position     : static;
      margin-inline: -15px;
    }

    &__pack,
    &__content {
      max-width: 100%;
    }

    &__content {
      margin-top: -100px;
    }
  }
}

// Отзывы
.reviews {
  &__pack {
    max-width: 1030px;

    &--1 {
      top : -395px;
      left: -675px;
    }

    &--2 {
      bottom: -395px;
      right : -675px;
    }
  }

  &__image {
    transform: rotate(180deg);
  }

  &__bottom {
    background-color: var(--color-blue-10);
  }

  @media (min-width: 2000px) {
    &__bg {
      max-width: 1920px;
    }
  }
}

// Вопросы
.questions {
  &__item {
    &--blue {
      background-color   : var(--color-blue-10);
      border-bottom-color: var(--color-blue-60);
    }
  }

  @media(max-width: $lg) {
    &__items {
      flex-direction: row;
    }
  }

  @media(max-width: $md) {
    &__items {
      flex-direction: column;
    }
  }
}

// Квиз
.quiz {
  &-images {
    &__pack {
      max-width          : 450px;
      background         : var(--color-primary) url('../img/pictures/firework.png') no-repeat center / cover;
      background-position: 100px;

      &::before {
        padding-top: 135%;
      }
    }

    &__image {
      top : 40px;
      left: -40px;
    }
  }

  &-slider {
    &[data-quiz="stop"] .swiper-button-next {
      pointer-events: none;
      opacity       : 0.5;
    }

    &[data-quiz-end] &__btn--next {
      display: none;
    }

    &[data-quiz-end] &__submit {
      display: flex;
    }

    &__btn {
      top  : 0;
      left : 0;
      right: 0;

      &--prev {
        min-width: 60px;
        width    : 60px;
      }
    }
  }

  &__content {
    max-width: 440px;
  }

  @media(max-width: 1300px) {
    &-images {
      &__pack {
        max-width: 300px;
      }
    }
  }
}

.footer-bottom {
  color: rgb(255, 255, 255);
  padding-top: 0;
  padding-bottom: 0;
}

.footer-bottom-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-top: 1px solid rgba(217, 217, 217, 0.2);
}

.footer-bottom-link {
  text-decoration: underline;
  text-underline-offset: 4px;
}
