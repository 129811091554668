// Тело документа
body {
  color           : var(--color-black);
  background-color: var(--color-white);

  &[data-theme="dark"] {
    --color-black: #FFFFFF;
    --color-white: #151515;
    --color-grey : #222222;

    & [data-theme="light"],
    & .form-error,
    & .movement::after {
      --color-black: #151515;
      --color-white: #FFFFFF;
      --color-grey : #EFEFEF;
    }

    & .logo-dark {
      display: none;
    }

    & .logo-light {
      display: block;
    }

    & .header__btn,
    & .gallery__btn {
      --btn-accent: var(--color-black);
      border-color: var(--color-black);
    }

    & .btn--primary {
      --btn-accent: #FFFFFF;
    }

    & .input {
      border-color: var(--color-white-60);

      &::placeholder {
        color: var(--color-white-60);
      }
    }

    & .advantages__pack {
      background: none !important;
    }
  }
}

// Выделение
::selection {
  color           : var(--color-white);
  background-color: var(--color-primary);
}