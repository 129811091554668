// Цвета
$colors: ("none": rgba(0, 0, 0, 0),
  "primary": #0E1F2C,
  "second": #8F5251,
  "black": #151515,
  "white": #FFFFFF,
  "grey": #EFEFEF,
  "blue": #00467B);

// Шрифты
$font-family: ("alt": "Gilroy",
  "base": "Futura PT"
);

// Разрешения экрана
$sm: 459.98px;
$md: 767.98px;
$lg: 1199.98px;

// Интервал
$spacing: 4px;

// Переменные в CSS
:root {

  @each $key,
  $value in $font-family {
    --font-#{$key}: "#{$value}",
    Arial,
    Helvetica,
    sans-serif;
  }

  @for $i from 5 through 25 {
    --text-#{calc(2 * $i)}: #{calc(2 * $i / 16) + rem};
  }

  @each $key,
  $value in $colors {
    @include getColors($value, $key);
  }

  --sm : #{calc($sm - 29.98px)};
  --md : #{calc($md - 47.98px)};
  --lg : #{calc($lg - 29.98px)};

  --size-lg : 60px;
  --size-sm : 38px;
}