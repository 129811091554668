// Карточка
.card {
  display         : flex;
  flex-direction  : column;
  position        : relative;
  background-color: var(--color-white);
  transition      : transform 0.2s ease;
  overflow        : hidden;

  &-content {
    display       : flex;
    flex-direction: column;
    flex-grow     : 1;
  }

  &--active:active {
    transform: translateY(4px);
  }
}