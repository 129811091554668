// Прелоадер
.preloader {
  position       : fixed;
  inset          : 0;
  z-index        : 325;
  display        : flex;
  align-items    : center;
  justify-content: center;
  background     : var(--color-white);
  transition     : .5s ease;
}

body.load-complete .preloader {
  opacity       : 0;
  visibility    : hidden;
  pointer-events: none;
}

// Обертка
.smooth-wrapper {
  padding: inherit;
}

// Логотип
.logo {
  width: 220px;

  @media(max-width: $md) {
    & {
      width: 150px;
    }
  }
}

// Шапка документа
.header {
  top      : 0;
  left     : 0;
  right    : 0;
  transform: translateY(var(--top));

  &__btn {
    border-color: var(--color-black-20);
  }
}

// Мобильное меню
.mobile {
  inset: 0;

  &__content {
    max-width : 425px;
    box-shadow: 0 0 0 100vw var(--color-black-50);
  }

  &:not([data-mobile="open"]) {
    opacity   : 0;
    visibility: hidden;
  }

  &:not([data-mobile="open"]) &__content {
    transform : translateX(100%);
    opacity   : 0;
    visibility: hidden;
  }

  &[data-mobile="open"] {
    opacity   : 1;
    visibility: visible;
  }

  &[data-mobile="open"] &__content {
    transform : translateX(0);
    opacity   : 1;
    visibility: visible;
  }
}

// Подвал
.footer {
  &__btn {
    min-width: 32px;
    width    : 32px;
    height   : 32px;

    &--wa {
      background: #26D044;
    }

    &--tg {
      background: #33AADE;
    }
  }

  @media(max-width: $md) {
    & {
      flex-direction: column;
    }
  }
}

// Модальное окно
.dialog {
  &--detail {
    width: 360px;
  }

  &__pack {
    padding-top: 30%;
  }

  @media(min-width: $lg) {

    &--politics,
    &--feedback {
      padding: 60px 64px;
    }

    &--submit {
      width        : 580px;
      padding-block: 100px;
    }
  }
}