// Поле
.input {
  font: {
    size  : var(--text-22);
    weight: 300;
  }

  flex-grow       : 1;
  width           : 100%;
  height          : var(--size-lg);
  background-color: var(--color-none);
  padding         : 16px;
  padding-left    : var(--size-lg);
  border-bottom   : 1px solid var(--color-black-60);
  transition      : 0.2s ease;
  user-select     : initial;

  &-cover {
    display: flex;
    width  : 100%;
  }

  &--error {
    border-color: var(--color-blue-60);
  }

  &:not(&--error):focus {
    border-color: var(--color-black);
  }

  &::placeholder {
    color: var(--color-black-60);
  }

  &:disabled {
    pointer-events: none;
    opacity       : 0.5;
  }
}